/* !
 * jQuery index v1.0.0
 * Copyright 2021, Connecty Inc.
 */
(function($, FUNCTIONS){
	const arrowPrev = '<button type="button" class="slick-prev"><svg><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></button>';
	const arrowNext = '<button type="button" class="slick-next"><svg><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></button>';

	// メインビジュアルスライダー
	const mainVisSlider = () => {
		$('.idx-main-slide').slick({
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplaySpeed: 5000,
			loop: true,
			infinite: true,
			arrows: false,
			dots: false
		});
	};

	// pickup スライダー
	const pickupSlider = () => {
		$('.idx-pickup-slide').slick({
			autoplay: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplaySpeed: 5000,
			loop: true,
			infinite: true,
			dots: true,
			arrows: true,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2
					}
				}
			]
		});
	};

	// enjoy スライダー
	const enjoyPickupSlider = () => {
		$('.idx-enjoy-headline-slide').slick({
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplaySpeed: 5000,
			loop: true,
			infinite: true,
			dots: false,
			arrows: false,
			variableWidth: true,
			// centerMode: true,
			// centerPadding: '40px',
			// responsive: [
			// 	{
			// 		breakpoint: 1024,
			// 		settings: {
			// 			slidesToShow: 3
			// 		}
			// 	},
			// 	{
			// 		breakpoint: 767,
			// 		settings: {
			// 			slidesToShow: 2
			// 		}
			// 	}
			// ]
		});
	};

	// enjoy シーン別 スライダー
	const enjoySceneSlider = () => {
		$('.idx-enjoy-scene-slide').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			autoplaySpeed: 5000,
			infinite: false,
			dots: false,
			arrows: true,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3
					}
				}
			]
		});
		if (FUNCTIONS.va.window.width >= 1024) {
			$('.idx-enjoy-scene .__head').css('height', $('.idx-enjoy-scene-slide img:first-child').height());
		}
	};

	// レシピ空要素削除
	$('.mod-recipeList').find('li').each((idx, ele) => {
		if (!$(ele).find('a').length) {
			$(ele).remove();
		} else if (!$(ele).find('.rt_cf_r_cooking_time').length) {
			$(ele).find('.__time').remove();
		}
	});
	if (!$('.mod-recipeList').find('li').length) {
		$('.idx-recipe').remove();
	}

	// ========= ↑↑ FUNCTIONS ↑↑ ========================================================================== //

	mainVisSlider();
	pickupSlider();
	enjoyPickupSlider();
	enjoySceneSlider();


})(window.jQuery, window.FUNCTIONS);
